import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, LatinQuotes, Pagination } from "../../components"

const LatinQuotesPage = ({ data, pageContext }) => {
  const {
    allAirtable: { nodes: latinquotes },
  } = data

  return (
    <Wrapper>
      <Layout>
        <LatinQuotes
          title="Összes latin idézet"
          latinquotes={latinquotes}
          page
        />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background-color: var(--clr-light-3);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query LatinQuotesQuery($skip: Int, $limit: Int) {
    allAirtable(
      filter: { table: { eq: "LatinQuotes" } }
      limit: $limit
      skip: $skip
      sort: { fields: data___latin_quotes__row, order: DESC }
    ) {
      nodes {
        id
        data {
          latin_quotes__row
          latin_quotes__title
          latin_quotes__author
          latin_quotes__quote {
            childMarkdownRemark {
              html
            }
          }
          latin_quotes__magyarul {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default LatinQuotesPage
